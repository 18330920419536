import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import Logo from '../../../images/icon.png';
import HTMLReactParser from 'html-react-parser';
import NewsBanner from "../../StaticPage/StaticBanner/NewsBanner"
import MagazineSidebar from "../../Sidebar/MagazineSidebar"
import OtherMagazine from "./OtherMagazine";
import Breadcrumbs from "../../Footer/Breadcrumb/Breadcrumb";
// import "./News.scss"
import { Link } from "@StarberryUtils";
import _ from "lodash";
import logoBlack from "../../../images/logoblack.svg";
import SEO from "../../seo";

// const GET_MAGAZINES_DETAIL = gql`
//   query GetMagaZines($url: String!){	
//     magazines(sort:"Created_Date:asc", where:{URL:$url})  {
//         id
//         About_Magazine
//         Created_Date
//         Tile_Image {
//           url
//           alternativeText
//         }
//         Featured
//         Title
//         Magazine_Embeded_And_Content
//         URL
//         Magazine_Major_Points {
//           Add
//         }
//         Banner_CTA_1_Label
//         Banner_CTA_1_Link
//         Banner_CTA_2_Label
//         Banner_CTA_2_Link
//       }
//   }
// `;

const MagazineDetail = (props) => { 

  // const { loading, error, data } = useQuery(GET_MAGAZINES_DETAIL, { variables: { url: props.slug } });
  
  // if (loading) return (<><NewsBanner /><div className="gql-loading-screen"><img src={Logo} alt="" /><p>Loading ...</p></div></>);

  // if (loading) return (
  //   <section className={"loader-wrapper"}>
  //     <div id="loader-wrapper">
  //       <div id="loader" class="new-loader">
  //         {/* <div className="new-loader-anime"></div> */}
  //         <img className="logo-white" src={logoBlack} className="loader-logo" alt="DB Roberts logo" />
  //       </div>
  //       <div class="loader-section section-left"></div>
  //       <div class="loader-section section-right"></div>
  //     </div>
  //   </section>
  // );

  var magazineDetail = props.magazine;
  // if (!_.isEmpty(data.magazines)) {
  //   magazineDetail = data.magazines[0];
  // }
  const description = magazineDetail.About_Magazine
  ? HTMLReactParser(magazineDetail.About_Magazine.replace(/<br\s*\/?>/gi, ''))
  : "";
  
  return (
    <>
      <SEO title={magazineDetail.Title} description={`${description?.props?.children} | DB Roberts`} image={magazineDetail?.Tile_Image?.formats?.small?.url} />
      <div className="magazine-detail">
        <section className="static-banner">
          <Container>
            <Row>
              <Col>
                <div className="banner-content">
                  {magazineDetail.Title &&
                    <h2>{magazineDetail.Title}</h2>
                  }
                  {description &&
                    <p>{description}</p>
                  }
                  {magazineDetail.Title &&
                    <div className="banner-section-btn">
                      {
                        magazineDetail.Banner_CTA_1_Label && magazineDetail.Banner_CTA_1_Link && (
                          <Link to={magazineDetail.Banner_CTA_1_Link} className="btn btn-primary">{magazineDetail.Banner_CTA_1_Label}<i className="icon-arrow"></i></Link>
                        )
                      }

                      {
                        magazineDetail.Banner_CTA_2_Label && magazineDetail.Banner_CTA_2_Link && (
                          <Link to={magazineDetail.Banner_CTA_2_Link} className="btn btn-primary">{magazineDetail.Banner_CTA_2_Label}<i className="icon-arrow"></i></Link>
                        )
                      }

                    </div>
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="guide-details">
          <Container>
            <Row>
              <Col xl={(magazineDetail.Magazine_Major_Points.length > 0) ? 8 : 12} lg={(magazineDetail.Magazine_Major_Points.length > 0) ? 7 : 12}>
                <div className="guide-info">
                  {magazineDetail.Magazine_Embeded_And_Content &&
                    HTMLReactParser(magazineDetail.Magazine_Embeded_And_Content.replace(/<br\s*\/?>/gi, ''))
                  }
                </div>
              </Col>
              {magazineDetail.Magazine_Major_Points.length > 0 &&
                <Col xl={4} lg={5}>
                  <MagazineSidebar points={magazineDetail.Magazine_Major_Points} />
                </Col>
              }
            </Row>
          </Container>
        </section>
        <OtherMagazine magId={magazineDetail.id} />
        <Breadcrumbs tag="details" category="Magazines" subCategory={null} data={[]} page={magazineDetail.Title} />
      </div>
    </>
  )
}
export default MagazineDetail