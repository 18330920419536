import React, {useState} from "react"
import { graphql } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss"; 
import "../scss/custom.scss";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import MagazineDetail from "../components/StaticPage/Magazine/MagazineDetail";

const MagazineTemplate = ({data}) => {
    const GQLPage = data.glstrapi?.magazine;
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
  

    const handlerClick = () => {
        var root = document.getElementsByTagName('html')[0];
        setShowMenu(!showMenu)
        if (showMenu == true) {
            document.body.className = '';
            root.className = '';
        } else {
            document.body.classList.add("overflow-hidden");
            root.classList.add("overflow-hidden");
        }
    }

    const handlerSearchClick = () => {
        setShowSearch(!showSearch)
        setShowMenu(false)
        if (showSearch == true) {
            document.body.className = '';

        } else {
            document.body.classList.add("overflow-hidden");
        }
    }

    return (
        <div className={` ${(showMenu || showSearch) ? "wrapper open-search-block" : "wrapper"}`} > 
            <Header showMenu={showMenu} handlerClick={handlerClick} handlerSearchClick={handlerSearchClick} showSearch={showSearch} fixed={true} /> 
            <MagazineDetail magazine={GQLPage}  />
            <Footer popularSearch={'Popular_Search_Home'} popularSearchType="static" />
        </div>
    )
}

export default MagazineTemplate;

export const pageQuery = graphql`
    query MagazineQuery($id: ID!) {
        glstrapi {
            magazine(id: $id, publicationState: LIVE) {
                id
                About_Magazine
                Created_Date
                Tile_Image {
                    url
                    alternativeText
                    formats
                }
                Featured
                Title
                Magazine_Embeded_And_Content
                URL
                Magazine_Major_Points {
                    Add
                }
                Banner_CTA_1_Label
                Banner_CTA_1_Link
                Banner_CTA_2_Label
                Banner_CTA_2_Link
            }
        }
    }
`